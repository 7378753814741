<template>
  <div class="articles">
    <ArticlesThreeColumns
      :cfgTitle="`${cfg.nameCategory}`"
      :cfgCategory="`${cfg.category}`"
    ></ArticlesThreeColumns>
  </div>
</template>

<script>
import ArticlesThreeColumns from '@/components/Custom/Articles/ThreeColumns.vue';

export default{
  name: "ArticlesFamiliasEnRetorno",
  components:{
    ArticlesThreeColumns,
  },


  data() {
    return {
      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
      cfg:{
        showMenu: this.$route.meta.cfgShowMenu,
        category: this.$route.meta.cfgCategory,
        nameCategory: this.$route.meta.cfgNameCategory,
      },
    }
  },

  methods: {}

}
</script>